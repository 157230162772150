/* Hero section */
.hero-section {
    background-color: #FF7F50;
    color: #fff;
}

.home-title {
    font-size: 36px;
    line-height: 1.4em;
    text-transform: uppercase;
    font-weight: 700;
}

.home-subtitle {
    font-size: 20px;
    line-height: 1.3em;
    font-weight: 400;
}

.home-topic {
    font-size: 20px;
    text-transform: uppercase;
}

.home-btn {
    text-decoration: none;
}

.dark-text-color {
    color: #333;
}

/* styles.css */
.left-content {
  opacity: 0;
  transform: translateX(-50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.right-content {
    opacity: 0;
    transform: translateX(50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.down-content {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in-left {
  opacity: 1;
  transform: translateX(0);
}

.fade-in-right {
  opacity: 1;
  transform: translateX(0);
}

.fade-in-down {
    opacity: 1;
    transform: translateY(0);
  }

/* CSS */
.button-87 {
  padding: 12px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 25px;
  border: 0px;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  &:active {
    transform: scale(0.95);
  }
}

/* Overview section */
.overview-section {
    // background-color: #00ffd5;
}

.overview-content-box {
    background-color: #F09819;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 200px;
    color: #fff;
    text-decoration: none;

    .content-img {
        border-radius: 50%;
        background-color: #fff;
        width: 100px;
    }

    .content-title {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 2px;
    }

    &:hover {
        transition: all 0.4s ease;
        transform: translate(-4px, -4px);
        box-shadow: 5px 5px black;

        .content-img {
            transition: all 0.4s ease;
            transform: translate(-10px, -10px);
            box-shadow: 10px 10px black;
        }
    }
}

.content-box-list {
    margin-left: -15px;
    margin-right: -15px;
    display: grid;
    gap: 16px;
}

/* Small screen */
@media screen and (min-width: 576px) {
    .content-box-list, .home-blog-list, .project-list {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* Medium screen */
@media screen and (min-width: 768px) {
    .content-box-list, .home-blog-list, .project-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Large screen */
@media screen and (min-width: 992px) {
    .content-box-list, .home-blog-list, .project-list {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Career section */

.career-section {
    background-color: #2ECC71;
}

.vertical-timeline-element-content {
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.vertical-timeline-element-date {
    color: #fff;
    font-weight: 700 !important;

}

/* Badge section */
.badge-section {
    // background-color: yellow;
    background-color: #f7dc6f;
}

.badge-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.octagon {
    // display: block;
    width: 80px;
    height: 80px;
    /* background-color: #EDEFEE; */
    clip-path: polygon(50% 0%, 85% 15%, 100% 50%, 85% 85%, 50% 100%, 15% 85%, 0% 50%, 15% 15%);
    display: flex;
    align-items: center;
    justify-content: center;
    // background: linear-gradient(to right, rgb(255, 81, 47), rgb(240, 152, 25));
    // background-color: #F7DC6F;
    // background-color: #F1C40F;
    // background-color: coral;
    background-color: #fff;

    &:hover {
        scale: 1.2;
        transition: all 0.4s ease;
        animation: rotate 4s linear infinite;
    }
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
}

.badge-img  {
    text-align: center;
    img {
        width: 50%;
        margin: auto;
    }
}

/* Project section */
.home-project {
    background-color: #DE3163;
    color: #fff;
}

.home-project .project-list {
    display: grid;
    gap: 30px;

    .home-project-card {
        display: flex;
        flex-direction: column;
        // border-radius: 20px;
        // background-color: #f096193a;
        background-color: #fff;
        color: #333;

        .home-project-img {
            img {
                width: 100%;
                // border-radius: 20px 20px 0 0;
            }
        }

        .home-project-card-content a {
            text-decoration: none;
            display: block;
        }
    }
}

/* Small screen */
@media screen and (min-width: 576px) {
    .home-project .project-list {
        grid-template-columns: repeat(1, 1fr);
    }
}

/* Medium screen */
@media screen and (min-width: 768px) {
    .home-project .project-list {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Large screen */
@media screen and (min-width: 992px) {
    .home-project .project-list {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Blog section */
.home-blog-section {
    background-color: #8E44AD;
    color: #fff;
    display: flex;
    flex-direction: column;

    .blog-header ul {
        list-style: none;

        li {
            float: left;
            &:not(:first-child) {
                margin-left: 20px;
            }

            a {
                text-decoration: none;
                // color: black;
                color: #fff;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .active-tab {
            text-decoration: underline;
        }
    }
}

.home-blog-list {
    padding: 20px;
    display: grid;
    gap: 20px;
}

.blog-post {
    display: flex;
    flex-direction: column;
    background-color: #F5EEF8;
    color: #333;

    .blog-title {
        font-weight: 600;
        font-size: 18px;
    }

    .blog-content .blog-sub-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

/* CSS */
.button-85 {
    margin: auto;
    font-size: 14px;
    padding: 0.6em 1em;
    border: none;
    outline: none;
    color: rgb(255, 255, 255);
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    &::before {
        content: "";
        background: linear-gradient(
            45deg,
            #ff0000,
            #ff7300,
            #fffb00,
            #48ff00,
            #00ffd5,
            #002bff,
            #7a00ff,
            #ff00c8,
            #ff0000
        );
        position: absolute;
        top: -2px;
        left: -2px;
        background-size: 400%;
        z-index: -1;
        filter: blur(5px);
        -webkit-filter: blur(5px);
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        animation: glowing-button-85 20s linear infinite;
        transition: opacity 0.3s ease-in-out;
        border-radius: 10px;
    }

    &::after {
        z-index: -1;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: #222;
        left: 0;
        top: 0;
        border-radius: 10px;
    }
}


@keyframes glowing-button-85 {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}

/* CSS */
.button-31 {
    font-size: 12px;
    background-color: #222;
    border-radius: 4px;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: "Farfetch Basis","Helvetica Neue",Arial,sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
    max-width: none;
    min-height: 44px;
    min-width: 10px;
    outline: none;
    overflow: hidden;
    padding: 9px 20px 8px;
    position: relative;
    text-align: center;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;

    &:hover, &:focus {
        opacity: .75;
    }
}

/* Contact section */
.contact-section {
    background-color: #3498DB;
    .contact-form {
        align-self: center !important;
        form {
            display: grid;
            background-color: #85C1E9;

            input {
                // border-color: #111;
                // border: none;
                // height: 40px;
                // padding: 0 10px;
                // font-size: 16px;
            }

            .form-textarea {
                // height: 120px;
                // resize: none;
                // border: none;
                // padding: 10px;
                // font-size: 16px;
                // box-sizing: border-box; /* Include padding and border in the total width and height */
            }

            .form-btn {
                background-image: linear-gradient(45deg, #00B4DB, #0083B0);
                box-shadow: none;
            }
        }
    }
}

/* Footer section */
#homeFooter {
    // background-color: #F39C12;
    // background-color: #333;
    background-color: #F39C12;
    .social-icons {
        gap: 20px;

        .icon-style-1 {
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            background-color: #fff;
            border-radius: 50%;
            padding: 10px;

            &:hover {
                transition: all 0.3s ease;
                transform: translate(-3px, -3px);
                box-shadow: 3px 3px black;
            }
        }
    }
}
