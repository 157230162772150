// * {
//     font-family: 'Lato';
//     letter-spacing: 1px;
// }

// body {
//     color: white;
//     background-color: #080326;
// }

h1, h2, h3, h4, h5, h6 {
    clear: both;
    margin: 0;
    padding: 0;
    font-weight: 600;
    line-height: 1.2em;
    // font-family: 'Inter', sans-serif;
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
}

#projectHeader {
    .navbar {
        .navbar-brand {
            // padding-left: 20px;
        }

        .navbar-collapse {
            justify-content: flex-end;
        }

        .navbar-nav {
            gap: 12px;
            // padding-right: 20px;
        }

        .nav-item {
            cursor: pointer;

            .nav-link {
                color: #fff;
                font-weight: 600;
            }

            &:hover {
                .nav-link {
                    transition: all 0.3s ease;
                    color: #3772ff;
                }
            }
        }

        .navbar-toggler {
            color: #fff;
        }
    }
}

/* Project Hero Landing Section */
.container-fluid {
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
    max-width: 1650px;
}

.landing-section {
    min-height: 700px;
    padding-top: 90px;
    padding-bottom: 90px;

    .hero-in {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        // .hero-in-left {
        //     width: 55%;
        // }

        // .hero-in-right {
        //     width: 45%;
        // }
    }

    .hero-text {
        background: linear-gradient(90.41deg, rgba(255, 255, 255, 0.05) -0.38%, rgba(255, 255, 255, 0) 99.66%);
        border-radius: 25px;
        padding: 50px;
        max-width: 850px;
        color: #fff;
        letter-spacing: 0.05em;
    }

    .hero-title {
        font-size: 58px;
        margin-bottom: 25px;
        font-weight: 600;
    }

    .hero-subtitle {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 1.5em;
        font-weight: 500;
    }

    .hero-btns {
        display: flex;
        flex-wrap: wrap;

        .hero-btn:not(:last-child) {
            margin-right: 20px;
        }

        .hero-btn:last-child {
            background-color: #fff;
            color: #000;

            &:hover {
                span {
                    color: #fff;
                }
            }
        }
    }

    .hero-btn {
        text-decoration: none;
        background-color: #3772ff;
        color: #fff;
        font-weight: 500;
        display: inline-flex;
        padding: 9px 29px;
        justify-content: center;
        border-radius: 1.6rem;
        line-height: 1.55em;
        position: relative;

        span {
            position: relative;
            z-index: 1;
        }

        &::after {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            border-radius: inherit;
            background: linear-gradient(116.85deg, #FC466B 0%, #3F5EFB 100%);
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            opacity: 0;
        }

        &:hover {
            &::after {
                opacity: 1;
            }
        }
    }

    .hero-slider {

        .slick-list {
            padding: 60px 0 !important;
        }

        .slick-center {
            transform: scale(1.3);
            position: relative;
            z-index: 1;
            opacity: 1 !important;
            pointer-events: initial;
            // background-color: #050023;
            // background-color: #1B4F72;
            // background: linear-gradient(116.85deg, #24C6DC 0%, #514A9D90 100%);
            background-color: #4364F7;
            border-radius: 1rem;
            transition: all 0.4s ease;
        }

        .slick-slide {
            opacity: 0.5;
        }
    }
}

@media screen and (max-width: 600px) {
    .landing-section {
        .hero-slider {
            .slick-center {
                transform: scale(1.0);
            }
        }

        .hero-text {
            padding: 0;
        }
    }
}

.hero-bg {
    // background-image: url('../../public/assets/project-page/hero_bg4.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.hero-center {
    display: flex;
    align-items: center;
    justify-content: center;
}



/* Project Section */
.project-section {
    margin-top: 3rem;
    margin-left: 5%;
    margin-right: 5%;
}

.project-tab-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -47px;

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        list-style: none;
        background: linear-gradient(116.85deg, rgba(252, 70, 107, 0.2) 0%, rgba(63, 94, 251, 0.2) 100%);
        border-radius: 1.6em;

        li {
            padding: 0;
            cursor: pointer;

            &:not(.active):hover {
                a span {
                    color: #3772ff;
                    transition: 0.3s;
                }
            }
        }

        .active {
            border-radius: 1.6em;

            a {
                &::before {
                    opacity: 1;
                }
            }
        }
    }

    a {
        text-decoration: none;
        color: #fff;
        padding: 8px 20px;
        position: relative;
        display: inline-block;
        border-radius: 1.6em;

        &::before {
            content: '';
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            border-radius: inherit;
            background: linear-gradient(116.85deg, #FC466B 0%, #3F5EFB 100%);
            transition: all 0.3s ease;
        }

         span {
            font-weight: bold;
            position: relative;
            z-index: 1;
         }
    }

}


/* FIXING CSS */
.project-body-dark-theme {
    // background-color: #3498DB;
    // background-color: #080326;
    // background:  linear-gradient(116.85deg, #5433ff 0%, #20bdff 50%, #a5fecb 100%);
    // background: linear-gradient(to right, #5433ff, #20bdff, #a5fecb);
    background: linear-gradient(116.85deg, #0575E6, #021B79);
    color: rgba(255, 255, 255, 0.8);
}

.container {
    padding-left: 15px;
    padding-right: 15px;
    max-width: 1350px;
}

.height_45 {
    height: 45px;
}

.project-section-heading {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: -7px;

    .project-section-title {
        color: #fff;
        font-size: 36px;
        margin: 0;
    }

    .project-section-heading-left {
        margin: 7px;
    }

    .project-section-heading-right {
        margin: 7px;

        span {
            font-size: 16px;
            font-weight: 600;
            padding: 8px 25px;
            line-height: 1.5em;
        }
    }
}

.isotope-gutter-30 {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -30px;
    box-sizing: border-box;
}

.grid-sizer {
    width: 20%;
}

.grid-item {
    width: 20%;
    box-sizing: border-box;
    padding: 0 15px;
    margin-bottom: 25px;
}

.project-item {
    // background: linear-gradient(116.85deg, rgba(252, 70, 107, 0.2) 0%, rgba(63, 94, 251, 0.2) 100%);
    background: linear-gradient(116.85deg, #1e3c7220 0%, #2a529820 100%);
    // background-color: #1B4F72;
    position: relative;
    padding: 10px;
    border-radius: 15px;
}

.project-card-thumb {
    border-radius: 15px 15px 0px 0px;
    overflow: hidden;
    display: block;

    img {
        width: 100%;
        border-radius: inherit;
    }
}

.project-zoom-effect {
    position: relative;
    overflow: hidden;

    .project-zoom-item {
        transition: all 0.7s ease;
        &:hover {
            transform: scale(1.05);
        }
    }
}

.project-card-info {
    padding: 0 10px;

    hr {
        margin: 0.5rem 0;
    }

    .project-card-title {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .project-card-skill {
        font-size: 14px;
        line-height: 1.6em;
        margin-bottom: 10px;
    }

    .project-card-footer {
        padding: 10px 0;
        margin: -3px;
        text-align: center;
    }
}

.card-btn-1 {
    position: relative;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    background-color: #3772ff;
    border-radius: 1.6em;
    line-height: 1.5em;
    padding: 7px 16px;
    transition: all 0.3s ease;
    cursor: pointer;

    span {
        position: relative;
        z-index: 1;
    }

    &::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: inherit;
        background: linear-gradient(116.85deg, #FC466B 0%, #3F5EFB 100%);
        // background:  linear-gradient(116.85deg, #5433ff 0%, #20bdff 50%, #a5fecb 100%);
        transition: all 0.4s ease;
        opacity: 0;
    }

    &:hover {
        &::after {
            opacity: 1;
        }
    }
}

@media screen and (max-width: 1024px) {
    .grid-item {
        width: 25%;
        padding: 0 10px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 600px) {
    .grid-item {
        width: 50%;
        padding: 0 5px;
        margin-bottom: 10px;
    }

    .project-tab-bar {
        margin-top: 25px;
    }
}

@media screen and (max-width: 480px) {

}

/* Project Footer */
.project-footer {
    // background-color: #050023;
    position: relative;
    color: #d4d4d4;
    font-weight: 500;
    list-style: 1.6em;

    .project-footer-widget {
        margin: 60px auto;

        .widget-title {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
            letter-spacing: 0.1rem;
        }

        .footer-social-btns {
            margin: 20px -10px -10px -10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            a {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 7px;
                width: 42px;
                height: 42px;
                color: #fff;
                margin: 10px;
                text-decoration: none;
                transition: all 0.3s ease;

                i {
                    color: #fff;
                    position: relative;
                    z-index: 1;
                }

                &::after {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    border-radius: inherit;
                    left: 0;
                    top: 0;
                    background: linear-gradient(116.85deg, #FC466B 0%, #3F5EFB 100%);
                    opacity: 0.15;
                    transition: all 0.3s ease;
                }

                &:hover::after {
                    opacity: 0.5;
                }
            }
        }
    }
}
