#pageWrapper {
    transition: all 0.5s ease;
    overflow-x: hidden;

    .container {
        padding-left: 15px;
        padding-right: 15px;
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 768px) {
    #pageWrapper {
        .container {
            width: 750px;
        }

        .row {
            margin-left: 230px;
        }
    }
}

@media (min-width: 992px) {
    #pageWrapper {
        .container {
            width: 970px;
        }
    }
}

@media (min-width: 1200px) {
    #pageWrapper {
        .container {
            width: 1170px;
        }
    }
}

@media only screen and (min-width: 1600px) {
    #pageWrapper {
        .container {
            width: 1200px;
        }
    }
}

/* Intro section */
#pageContentWrapper {
    position: relative;
    transition: all 0.5s ease;
    z-index: 100;

    section {
        padding: 80px 0;
        background-color: #fff;
    }
}

sup {
    color: #ffb03a;
}

h1, h2, h3 {
    display: inline-block;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    font-weight: 700;
}

img {
    vertical-align: middle;
}

/* About header */
#pageSidebarWrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: #111;
    padding: 24px 32px;
    width: 230px;
    transition: all 0.5s ease;
    z-index: 200;
}

.responsive-menu {
    width: 100%;
    background-color: #111;
    z-index: 150;
    position: fixed;
    padding: 10px 30px;
    display: none;
    transition: all 0.5s ease;
    top: 0;
}

.responsive-menu-heading {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.38889rem;
    color: #fff;
    display: table-cell;
    vertical-align: middle;
    transition: all 0.5s ease;
}

.responsive-menu-toggle {
    float: right;
    display: table-cell;
    vertical-align: middle;

    img {
        height: 25px;
    }
}

.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav > li {
    position: relative;
    display: block;
}

.logo-wrapper {
    z-index: 300;
    img {
        width: 100%;
    }
}

.top-menu {
    margin-top: 32px;
    position: relative;
    display: block !important;

    li {
        display: block;
        text-transform: uppercase;
        font-weight: 700;
        padding: 5px 0;

        a {
            display: block;
            color: #f7f7f7;
            transform: translateZ(0);
            backface-visibility: hidden;
            position: relative;
            overflow: hidden;
            padding: 0;
            margin-bottom: 10px;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 100%;
                bottom: 0;
                background-color: #ffb03a;
                height: 2px;
                transition: right 2s ease;
            }
        }
    }

    .active a, .current a {
        color: #ffb03a;
        &::before {
            right: 0;
        }
    }

    .menu-next-btn, .menu-prev-btn {
        color: #ffb03a;
        position: absolute;
        top: 2px;
        display: none;
        font-size: 13px;
        cursor: pointer;
        padding: 5px;
    }

    .menu-next-btn {
        right: -5px;
    }

    .menu-prev-btn {
        left: -5px;
    }

    .child-menu {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease;
        top: -18px;
        right: -228px;
        background-color: #111;
        padding: 18px 0;

        li {
            padding: 5px 32px 5px 64px;
            a {
                &::before {
                    right: 100%;
                }
            }
        }
    }
}

/* ------ */

.section-intro {
    // background: url("assets/Logo-cute-3.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    padding: 80px 0 !important;
    background-color: #000 !important;

    .section-content {
        padding: 80px 0;
        color: #fff;

        h1 {
            font-size: 5.33333rem;
            span {
                display: block;
                font-weight: 700;
            }
        }

        h2 {
            font-size: 1.77778rem;
            display: inline-block;
            color: #d4d4dd;
            border-top: 1px solid #d4d4dd;
            border-bottom: 1px solid #d4d4dd;
            padding: 15px 0;
            margin: 30px 0;
        }
    }
}

a {
    color: #ffb03a;
    text-decoration: none !important;
    &:hover {
        color: #ffb03a;
        text-decoration: none;
    }
}

.about-btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;

    &:hover {
        color: #333;
        text-decoration: none;
    }
}

.btn-cta {
    background: transparent;
    border: 3px solid #ffb03a;
    color: #ffb03a;
    text-transform: uppercase;
    font-weight: 700;
    padding: 12px 48px;
    text-align: center;
    font-size: 1rem;
    transition: all 0.5s ease;
    border-radius: 2px;
    outline: none !important;
    box-shadow: none !important;

    &:hover {
        background: #fff;
        border-color: #fff;
    }
}

.btn-cta-solid {
    color: #fff;
    background: #ffb03a;
}

/* About me section */
.section-about {
    .btn-cta {
        margin: 15px 0;
        display: block;
        width: 100%;
        padding: 15px 0;
    }
}

.section-heading {
    h2 {
        font-size: 2.22222rem;
        border-bottom: 3px solid #ffb03a;
        padding: 6px 0;
    }
}

.section-content {
    .block-about-me {
        margin: 40px 0;

        .about-avatar {
            border: 3px solid #ffb03a;
            width: 100%;
        }

        .about-me-list {
            font-size: 1.11111rem;

            li {
                margin-bottom: 25px;

                span {
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }
    }

    .block-strengths {
        text-align: center;

        .strength-icon {
            display: inline-block;
            width: 64px;
        }

        .strength-heading {
            display: block;
            font-weight: 700;
            font-size: 0.88889rem;
            margin: 15px 0;
            text-transform: uppercase;
        }
    }
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

/* Section skills */
.section-skills {
    background-color: antiquewhite;

    h3 {
        font-size: 1.22222rem;
        margin: 40px 0 22px;
        font-weight: 700;
    }

    p {
        font-weight: 500;
    }

    .block-circles-skills {
        padding: 45px 0;
    }

    .skill-wrapper {
        display: table;
    }

    .circle-skill {
        position: relative;
        display: table-cell;

        canvas {
            background-color: #fff;
            border-radius: 50%;
            display: inline-block;
            vertical-align: baseline;
        }

        strong {
            display: inline-block;
            position: absolute;
            top: 27%;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            text-align: center;
            font-weight: 700;
            font-size: 1.77778rem;
            i {
                font-weight: 500;
            }
        }
    }

    .circle-skill-heading {
        padding-left: 20px;
        display: table-cell;
        vertical-align: middle;
    }

    .my-skill-heading {
        font-weight: 700;
        font-size: 1.11111rem;
        text-transform: uppercase;
    }

    .my-skill-exp {
        margin-top: 12px;
        font-size: 0.88889rem;
        display: block;
    }

    .block-my-knowledge {
        h3 {
            font-size: 1.22222rem;
            margin: 40px 0 22px;
        }
    }

    .knowledge-list {
        margin: 40px 0;
        padding: 0;

        li i {
            margin-right: 10px;
        }
    }

    .block-my-languages {
        h3 {
            font-size: 1.22222rem;
            margin: 40px 0 22px;
        }
    }

    .skillbar-block {
        display: table;
    }

    .skillbar-exp {
        display: table-cell;
        vertical-align: middle;
        font-weight: 700;
        font-size: 2.66667rem;
        padding-right: 30px;

        i {
            font-weight: 500;
            font-style: normal;
        }
    }

    .skillbar-wrapper {
        display: table-cell;
        width: 100%;
        vertical-align: middle;
        .skillbar-heading {
            font-weight: 700;
        }
    }

    .skillbar-container {
        background-color: #ffb03a;
        position: relative;
        height: 7px;
        width: 100%;
        overflow: hidden;
        margin-top: 10px;
        .bar-skill {
            background-color: #d4d4dd;
            position: absolute;
            left: 0;
            top: 0;
            height: 7px;
            width: 100%;
        }
    }
}

/* Section experience */
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
}

.timeline {
    position: relative;
    list-style-type: none;
    padding: 30px 0 50px 0;
    &::before {
        content: '';
        position: absolute;
        width: 5px;
        height: 100%;
        left: 165px;
    }
}

.thumb {
    position: absolute;
    width: 100px;
    height: 100px;
    box-shadow: 0 0 0 8px rgba(65,131,142,1), 0 1px 1px rgba(255,255,255,0.5);
    background-repeat: no-repeat;
    border-radius: 50%;
    -webkit-transform: scale(0.8) translateX(24px);
    -moz-transform: scale(0.8) translateX(24px);
    -o-transform: scale(0.8) translateX(24px);
    -ms-transform: scale(0.8) translateX(24px);
    transform: scale(0.8) translateX(24px);
    transition: all 0.6s ease-in-out 0.2s;
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        left: 100%;
        margin-left: 8px;
    }
    span {
        color: #41838e;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        position: absolute;
    }
}

.content-inner {
    position: relative;
    padding: 20px;
    color: #333;
    border: none;
    &::before {
        content: '\25c2';
        font-weight: normal;
        font-size: 54px;
        line-height: 54px;
        position: absolute;
        width: 30px;
        height: 30px;
        color: #41838e;
        left: -22px;
        top: 19px;
        z-index: -1;
    }
}

.block-timeline {
    .thumb {
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 0%;
        background-color: #fff;
        box-shadow: 0 0 0 8px #ffb03a, 0 1px 1px rgba(255, 255, 255, 0.5);
        &::before {
            transition: all 0.5s ease;
            height: 1px;
            top: 44px;
            width: 45px;
        }
    }
    .company1 {
        background: #41838e;
    }

    .content-inner {
        border-left: 6px solid #ffb03a;
        transition: all 0.5s ease;
        &::before {
            transition: all 0.5s ease;
        }
    }
}

.section-experience {

}

.block-timeline .timeline .content-inner {
    background: #f7f7f7;
}

.content-perspective {
    margin-left: 230px;
    position: relative;
    -webkit-perspective: 600px;
    -moz-perspective: 600px;
    -o-perspective: 600px;
    -ms-perspective: 600px;
    perspective: 600px;
    &::before {
        content: '';
        width: 37px;
        left: 51px;
        top: 45px;
        position: absolute;
        height: 1px;
        z-index: -1;
        background: #fff;
    }
}

.content {
    transform-origin: 0 0;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(.59,1.45,.69,.98) 0.2s;
    transform: rotateY(10deg);
}

.event {
    position: relative;
    margin-bottom: 80px;
    padding-right: 40px;

    input[type="radio"] {
        opacity: 0;
        z-index: 10;
        cursor: pointer;
        width: 24px;
        height: 24px;
        left: 158px;
        top: 36px;
        position: absolute;
        display: block;
        &:checked ~ .thumb {
            transform: scale(1);
        }
        &:checked ~ .content-perspective .content {
            transform: rotateY(-5deg);
        }
        &:checked ~ .content-perspective .content-inner {
            border-color: #f26320;
            box-shadow: 10px 0 10px 6px rgba(0, 0, 0, 0.1);
            &::before {
                color: #f57f17;
            }
        }
    }

    label {
        width: 24px;
        height: 24px;
        left: 158px;
        top: 36px;
        position: absolute;
        display: block;
        &::after {
            // content: '\e702';
            content: '✔';
            background: #fff;
            border-radius: 50%;
            color: #41838e;
            font-size: 26px;
            height: 100%;
            width: 100%;
            left: 2px;
            top: -3px;
            line-height: 24px;
            position: absolute;
            text-align: center;
        }
    }
}

.section-experience {
    .block-my-experience {
        h3 {
            font-size: 1.22222rem;
            margin: 40px 0 22px;
            display: inline-block;
            text-transform: uppercase;
            padding: 0;
            font-weight: 700;
        }

        p {
            font-weight: 500;
        }
    }
}

.block-timeline .timeline {
    margin-top: 40px;
    &::before {
        background: #d4d4dd;
        width: 3px;
    }

    .event {
        input[type="radio"] {
            width: 100%;
            &:checked + label::after {
                content: '\2714';
                color: #f57f17;
            }
            &:checked ~ .thumb {
                box-shadow: 0 0 0 8px #f57f17, 0 1px 1px white;
                span {
                    color: #111;
                }
            }
            &:checked ~ .thumb::before {
                background: #ffb03a;
            }
            &:checked ~ .content-perspective::before {
                background-color: #ffb03a;
            }
            &:checked ~ .content-perspective .content-inner {
                border-color: #f57f17;
                box-shadow: 10px 10px 0 -6px rgba(0, 0, 0, 0.1);
            }
        }

        label {
            font-weight: normal;
            &::after {
                background: #fff;
                transition: all 0.5s ease;
                left: -3px;
            }
        }
    }

    .thumb {
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-border-radius: 0%;
        border-radius: 0%;
        -webkit-box-shadow: 0 0 0 8px #FFB03A, 0 1px 1px rgba(255, 255, 255, 0.5);
        box-shadow: 0 0 0 8px #FFB03A, 0 1px 1px rgba(255, 255, 255, 0.5);
        background-color: #fff;
        transition: all 0.6s ease-in-out 0.2s;

        span {
            bottom: -40px;
            font-weight: 700;
            font-size: 0.88889rem;
        }

        &::before {
            transition: all 0.5s ease;
            background: #D4D4D4;
            height: 1px;
            top: 44px;
            width: 45px;
        }
    }

    .content-perspective {
        &::before {
            left: -49px;
            background: #D4D4D4;
        }
    }
}

.event input[type="radio"]:checked + label::after {
    box-shadow: 0 0 0 5px #fff;
    color: #f57f17;
}

.content-perspective {
    margin-left: 230px;
    position: relative;
    perspective: 600px;
}

/* Section education */
.section-education {
    background-color: #f7f7f733 !important;
}

.block-my-education, .section-content {
    h3 {
        font-size: 1.22222rem;
        margin: 40px 0 22px;
        display: inline-block;
        text-transform: uppercase;
        padding: 0;
        font-weight: 700;
    }

    p {
        font-weight: 500;
    }
}

figure {
    margin: 0;
}

/* Section portfolio */
.section-portfolio {
    .section-content {
        margin-top: 40px;
        margin-bottom: 230px;
    }
}

/* Section contact */
.progress-button {
    position: relative;
    display: inline-block;
    padding: 0 60px;
    outline: none;
    border: none;
    background: #1d9650;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1em;
    line-height: 4;
}

.progress-button[data-style="fill"][data-horizontal] {
    overflow: hidden;
}

.section-contact {
    .block-my-contact-form {
        margin-top: 40px;
    }

    .block-my-contact {
        display: table;
    }

    .my-contact {
        display: table-row;

        img {
            display: table-cell;
            width: 50px;
        }

        figcaption {
            display: table-cell;
            vertical-align: middle;
            padding: 0 0 28px 28px;
        }

        .my-contact-heading {
            font-weight: 700;
            text-transform: uppercase;
            display: block;
        }

        .my-contact-data {
            display: block;
        }
    }

    .block-my-social {
        margin-bottom: 22px;

        h3 {
            display: block;
            font-size: 1.22222rem;
            margin: 40px 0 22px;
            padding: 0;
        }

        .social-links-list {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                display: inline-block;
                margin-right: 12px;
                margin-bottom: 12px;
            }
        }
    }

    .contact-form {
        font-size: 0.88889rem;

        input[type=text], input[type=email] {
            width: 100%;
            margin-bottom: 16px;
            padding: 15px;
            border: 2px solid #d4d4dd;
            border-radius: 2px;
            transition: all 0.5s ease;
        }

        textarea {
            width: 100%;
            min-height: 190px;
            resize: none;
            padding: 15px;
            border: 2px solid #d4d4dd;
            transition: all 0.5s ease;
        }

        button[type=submit] {
            margin-top: 24px;
            float: right;
            text-transform: uppercase;
            background: #ffb03a;
            font-weight: 700;
            color: #fff;
            border: none;
            border-radius: 2px;
            transition: all 0.5s ease;
            line-height: 48px;
            &:hover {
                background: #f57f17;
            }
        }
    }
}

.my-social-link {
    display: inline-block;
    filter: grayscale(100%);
    transition: all 0.5s ease;
    color: #ffb03a;
    &:hover {
        filter: none;
    }
}

#pageSidebarWrapper.toggled {
    transform: translate3d(230px, 0, 0);
    overflow-y: auto;
}

@media screen and (max-width: 992px) {
    .section-skills .skill-wrapper {
        margin-bottom: 64px;
    }
}

@media screen and (max-width: 768px) {
    .section-intro .section-content h1 {
        font-size: 2.5rem;
    }

    .responsive-menu {
        display: table;
    }

    #pageSidebarWrapper {
        left: -230px;
    }
}
