// * {
//     font-family: 'Lato';
//     letter-spacing: 1px;
//     line-height: 1.4rem;
// }

.blog-page-body {
    // background-color: #54cbca;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

h1 {
    line-height: 2.5rem;
}

body {
    margin: 0;
}

#blogHeader {
    .blog-navbar {
        border: 1px ridge black;
        border-radius: 3em;
        padding: 15px 30px;
        margin: 0 5%;
        // margin-top: 40px;

        .social-list {
            margin-left: 2em;
        }
    }
}

@media screen and (max-width: 991.98px) {
    .hide-on-mobile {
        display: none !important;
    }
}

.social-list {
    display: flex;
    gap: 1rem
}

a .fa-facebook-f {
    color: #175beb;
}

a .fa-instagram {
    color: #ef00a2;
}

.blog-button {
    text-decoration: none;
    font-size: 1rem;
    color: black;
    font-weight: 900;
    border: 1px ridge black;
    border-radius: 20px;
    padding: 5px 15px;
}

/* Category Box */

.category-box {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // margin: 70px 10%;
    padding: 20px 50px;
    border: 1px ridge black;
    border-radius: 20px;
}

.category-box .category-box-info {
    // flex: 1;
    text-align: center;
}

.category-box .category-box-content {
    // flex: 2;
    text-align: center;
}

.category-box-info h5 {
    margin-bottom: 1rem;
}

.category-box-info h6 {
    margin-top: 1rem;
}

p {
    font-size: 0.9rem;
}


/* Main Blog */

.blog-box {
    border: 1px ridge black;
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    padding: 20px;
}

.blog-img {
    // flex: 1;
    overflow: hidden;
    border: 1px ridge black;
    border-radius: 20px;

    &:hover {
        transition: all 0.3s ease;
        transform: translate(-2px, -2px);
        box-shadow: 3px 3px black;
    }
}

.blog-img img {
    width: 100%;
}

.blog-box .blog-summary {
    // flex: 1.5;
    padding: 10px 10px 10px 20px;
}

.blog-sub-info {
    display: flex;
    align-items: center;
    gap: 10px
}

.blog-info h1 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

.blog-interactions {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blog-interactions a {
    padding: 5px 25px;
    border-radius: 25px;
    border: 1px solid black;
    color: black;
    font-weight: bold;
    font-size: 0.8rem;
    text-decoration: none;
    transform: translate(-2px, -2px);
    box-shadow: 3px 3px black;
}

.blog-interactions a:hover {
    transition: 0.5s;
    transform: translate(0, 0);
    box-shadow: 0 0;
}

.blog-author {
    display: flex;
    align-items: center;
    gap: 10px;
}

.blog-author .blog-author-avatar {
    overflow: hidden;
    border: 1px ridge black;
    border-radius: 50%;
}

.blog-author-avatar img {
    width: 50px;
}

main aside {
    flex: 1;
    margin: 0 10px;
}

.about-me-aside {
    position: relative;
    padding: 20px 20px;
    border: 1px ridge black;
    border-radius: 20px;
    margin-bottom: 50px;
}

.about-title {
    position: absolute;
    top: -20px;
    left: 20px;
    border: 1px ridge black;
    border-radius: 30px;
    padding: 7px 15px;
    z-index: 1;
    font-weight: bold;
    background-color: white;
}

.about-author {
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-author .author-avatar {
    flex: 1;
    margin: 15px;
    border: 1px ridge black;
    border-radius: 50%;
    overflow: hidden;
}

.author-avatar img {
    width: 100%;
}

.about-author .author-info {
    flex: 2;
}

.author-info h4 {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.author-info .social-icons {
    margin-top: 10px;
    display: flex;
    gap: 10px;
}

.about-me-aside p {
    font-size: 0.8rem
}

.recent-posts-aside {
    border: 1px solid black;
    border-radius: 20px;
    padding: 25px;
    padding-top: 40px;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 50px;
}

.recent-posts-aside .recent-posts-title {
    position: absolute;
    top: -20px;
    left: 20px;
    padding: 7px 15px;
    border-radius: 20px;
    border: 1px solid black;
    z-index: 2;
    background-color: white;
}

.recent-post {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 25px;
}

.recent-post .recent-post-img {
    width: 100px;
    overflow: hidden;
    border: 1px solid black;
}

.recent-post-img img {
    width: 100%;
}

.follow-me-aside {
    border: 1px solid black;
    border-radius: 20px;
    padding: 25px;
    padding-top: 40px;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 50px;
}

.follow-me-aside .follow-me-title {
    position: absolute;
    top: -20px;
    left: 20px;
    padding: 7px 15px;
    border-radius: 20px;
    border: 1px solid black;
    z-index: 2;
    background-color: white;
}

.social-info {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
    text-decoration: none;
    color: #333;
}

/* Combine: width, height, line-height + vertical-align */
.social-info .social-icon {
    display: inline-block;
    width: 42px;
    height: 42px;
    line-height: 42px;
    border: 1px solid black;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
}

.social-info:hover .social-icon {
    transition: 0.5s;
    transform: translate(-2px, -2px);
    box-shadow: 2px 2px black;
}

.tag-aside {
    border: 1px solid black;
    border-radius: 20px;
    padding: 25px;
    padding-top: 40px;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 50px;
}

.tag-aside .tag-aside-title {
    position: absolute;
    top: -20px;
    left: 20px;
    padding: 7px 15px;
    border-radius: 20px;
    border: 1px solid black;
    z-index: 2;
    background-color: white;
}

.tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

// footer {
//     /* background-color: #54cbca; */
//     background-color: #fff5cb;
//     /* background: linear-gradient(135deg,#4aeadc 0%,#9778d1 20%,#cf2aba 40%,#ee2c82 60%,#fb6962 80%,#fef84c 100%); */
//     display: grid;
//     grid-template-columns: repeat(4, 1fr);
//     gap: 20px;
//     padding: 0 20px;
// }

// footer .footer-author {
//     align-self: center;
// }

// footer .footer-box {
//     margin: 0;
//     border: none;
// }

/* Blog Detail */
.main-section {
    flex: 2;
}

.blog-detail {
    padding: 20px;
    border: 1px solid black;
    border-radius: 20px;
}

.blog-detail-header {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.blog-detail-header .blog-img {
    flex: 1;
    border: 1px solid black;
    border-radius: 20px;
    overflow: hidden;
}

.blog-detail-header .blog-summary {
    flex: 1.5;
}

.blog-detail-content ol li {
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.blog-images {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.blog-images img {
    border: 1px solid black;
    border-radius: 20px;
}

/* display block + margin auto */
.img-layout-1 {
    margin-top: 20px;
    display: block;
    margin: auto;
    border: 1px solid black;
    border-radius: 20px;
}

.text-center {
    padding-top: 5px;
    display: block;
    text-align: center;
}

.quoted-text {
    margin: 20px 0;
    font-size: 0.9rem;
    font-style: italic;
    padding: 20px 30px;
    border: 1px solid black;
    border-radius: 20px;
    position: relative;
}

.quoted-text::after {
    content: "''";
    font-size: 3rem;
    position: absolute;
    right: 10px;
    bottom: 0;
}

.blog-detail-footer {
    margin: 30px 0;
    display: block;
    text-align: center;
}

.horizontal-line {
    height: 1px;
    border-top: 1px solid black;
    margin: 40px 0;
}

.blog-detail-footer h3 {
    font-weight: bold;
    margin: 15px 0 15px 0;
}

.blog-detail-footer h5 {
    font-weight: bold;
}

#subscribe-form {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 400px;
    position: relative;
}

#subscribe-form input[type="email"] {
    border: 1px solid black;
    border-radius: 25px;
    padding: 15px 20px;
    padding-right: 175px;
}

#subscribe-form button {
    font-weight: bold;
    font-size: 14px;
    height: 38px;
    padding: 0 20px;
    position: absolute;
    right: 40px;
    top: 9px;
    border-radius: 20px;
    color: white;
    background-color: #ff4c60;
    cursor: pointer;
}

.about-the-author {
    margin-top: 40px;
    // display: flex;
    // align-items: center;
    // gap: 10px;
    padding: 20px;
    border: 1px solid black;
    border-radius: 20px;
    position: relative;
}

.about-the-author .author-title {
    position: absolute;
    top: -20px;
    left: 20px;
    z-index: 1;
    border: 1px solid black;
    border-radius: 20px;
    padding: 10px 15px;
    background-color: white;
}

.about-the-author .author-information {
    // flex: 1.5;
}

.about-the-author .author-latest-articles {
    // flex: 1;
}

.inline-flex {
    display: inline-flex;
    align-items: center;
}

.block {
    display: block;
}

.button-style-1 {
    padding: 5px 15px;
    font-size: 0.9rem;
    border: 1px solid black;
    border-radius: 20px;
    transform: translate(-2px, -2px);
    box-shadow: 3px 3px black;
    text-decoration: none;
    color: #333;
}

.button-style-1:hover {
    transition: 0.3s;
    transform: translate(0, 0);
    box-shadow: 0 0;
}

.comment-section {
    margin-top: 40px;
    padding: 20px;
    position: relative;
    border: 1px solid black;
    border-radius: 20px;
}

.comment-section .comment-title {
    position: absolute;
    top: -20px;
    left: 20px;
    z-index: 1;
    background-color: white;
    border: 1px solid black;
    border-radius: 20px;
    padding: 10px;
}

.comment-view {
    margin-top: 20px;
    display: flex;
    gap: 20px;
}

.comment-view .commenter-avatar img {
    border: 1px solid black;
    border-radius: 50%;
}

.comment-view .comment-content .commenter-name {
    font-weight: bold;
}

.comment-view .comment-content .commenter-name span {
    font-weight: normal;
    font-size: small;
    padding-left: 10px;
}

.clear {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

.comment-section h4 {
    margin-top: 40px;
    margin-bottom: 20px;
}


#commentForm {
    margin-bottom: 20px;
}

/* border-box combine with padding */
#commentForm textarea {
    width: 100%;
    height: 170px;
    resize: none;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
    margin-bottom: 20px;
    box-sizing: border-box;
}

#commentForm .form-author {
    width: 48.75%;
    float: left;
    display: block;
    padding: 15px 25px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

#commentForm .form-email {
    width: 48.75%;
    float: right;
    display: block;
    padding: 15px 25px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

#commentForm .form-website {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 25px;
    margin-bottom: 20px;
}

.comment-form-cookies-consent {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.button-style-2 {
    color: white;
    background-color: #ff4c60;
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 25px;
    font-size: 1rem;
    cursor: pointer;
}

.button-style-2:hover {
    transition: 0.5s;
    transform: translate(-2px, -2px);
    box-shadow: 3px 3px black;
}

.related-articles {
    margin-top: 40px;
    padding: 20px;
    position: relative;
    border: 1px solid black;
    border-radius: 20px;
}

.related-articles .related-articles-title {
    position: absolute;
    top: -20px;
    left: 20px;
    z-index: 1;
    background-color: white;
    border: 1px solid black;
    border-radius: 20px;
    padding: 10px;
}

.related-articles-list {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

.related-article-box {
    display: flex;
    gap: 20px;
}

.related-article-box .related-article-img {
    width: 100px;
}

.related-article-img img {
    width: 100%;
    border: 1px solid black;
    border-radius: 10px;
}

#blogFooter {
    .recent-posts-aside, .follow-me-aside, .tag-aside {
        border: none;
    }
}

/* Blog Styles elements */

/* for blog title */
.title.large {
    font-size: 34px;
    line-height: 1.2;
    font-weight: 800;
    letter-spacing: .5px;
    color: #333;
}

.underline-effect a {
    cursor: pointer;
    color: #333;
    text-decoration: none;
    transition: all 0.4s ease;
    background-image: linear-gradient(to right, #333 0%, #333 100%);
    background-size: 0% 5%;
    background-repeat: no-repeat;
    background-position: left 80%;
    padding-bottom: 5px;

    &:hover {
        color: #333 !important;
        background-size: 100% 5%;
    }
}

.title.medium {
    font-size: 20px;
    line-height: 1.4;
    font-weight: 700;
}

.title.small {
    font-size: 15px;
    letter-spacing: .25px;
    font-weight: 700;
    color: #333;

    a {
        color: #333;
        text-decoration: none;
    }
}

/* for date & time read */
div.meta {
    font-size: 14px;
}

.author-name {
    font-weight: 500;
}

/* follow me title */
.name {
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
}

/* datetime recent post */
.item {
    font-size: 12px;
}

.tag-link {
    background-color: #fff;
    border: 1px solid;
    border-radius: 25px;
    color: #333;
    display: inline-block;
    padding: 6px 15px;
    line-height: 1.2;
    text-decoration: none;

    &:hover {
        transition: all 0.3s ease;
        transform: translate(-2px, -2px);
        box-shadow: 3px 3px black;
    }
}

.avatar-effect {
    &:hover {
        transition: all 0.3s ease;
        transform: translate(-2px, -2px);
        box-shadow: 3px 3px black;
    }
}

#blogFooter {
    background-color: #D5F5E3;
}


